import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { campaignUpdateDatesRequest } from '../../modules/actions';
import { Button } from '../../components/Form'
import { Modal } from '../../components'
import theme from '../../assets/css/theme';
import { DATE_FORMAT } from '../../config/datetime';
import { ExclusionDateFilter } from './ExclusionDateFilter';

const SUCCESS_MESSAGE_TIMEOUT = 6000

interface IProps {
  shouldShow: boolean
  handleUpdateDates: typeof campaignUpdateDatesRequest;
  campaignId: string;
  hasStarted: boolean;
  startDate: string;
  endDate: string;
  existingExclusionDates: string[];
}

interface IOnDatesChange {
  startDate: Moment | null;
  endDate: Moment | null;
}

type TFocusedDateType = 'startDate' | 'endDate';

const EditDatesButtons = ({ 
  shouldShow, 
  campaignId, 
  hasStarted, 
  handleUpdateDates,
  startDate: existingStartDate,
  endDate: existingEndDate,
  existingExclusionDates,
}: IProps) => {
  const [isEditExclusionDatesModalOpen, setIsEditExclusionDatesModalOpen] = useState(false)
  const [shouldShowEndNowSuccessMessage, setShouldShowEndNowSuccessMessage] = useState(false)
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [focusedDateType, setFocusedDateType] = useState<TFocusedDateType | null>(null)
  const [exclusionDatesApplied, setExclusionDatesApplied] = useState<string[]>([]);

  const onDatesChange = (newValues: IOnDatesChange) => {
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate)
  }

  const closeEditExclusionEndDateModal = useCallback(() => {
    setIsEditExclusionDatesModalOpen(false)
  }, [])

  const openEditExclusionDatesModal = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    setIsEditExclusionDatesModalOpen(true)
  }, [])

  const closeSuccessMessage = useCallback(() => setShouldShowEndNowSuccessMessage(false), [])

  const formattedExistingExclusionDates = !existingExclusionDates ? [] :  existingExclusionDates.map((date) => moment(date))

  const handleSave = (overrideEndDate: Moment | null = null) => {
    closeEditExclusionEndDateModal();

    if (!!startDate && !!endDate) {
      let endDateVal = moment.isMoment(overrideEndDate) ? overrideEndDate : endDate
      if (typeof endDateVal === 'string') {
        endDateVal = moment(endDateVal)
      }
  
      // if it's a string we stil need to format it as it won't have the correct format if it came from props
      const start_date = typeof startDate === 'string' ? moment(startDate).format(DATE_FORMAT) : startDate.format(DATE_FORMAT)
      const end_date = endDateVal.format(DATE_FORMAT)
      const exclusion_dates = [...new Set(exclusionDatesApplied)];

      handleUpdateDates({
        campaign_id: campaignId,
        start_date,
        end_date,
        exclusion_dates,
      })
  
      if (moment().diff(endDateVal, 'days') === 0) {
        setShouldShowEndNowSuccessMessage(true)
      }
    } 
  }

  const handleOutsiteRange = (date: Moment | null) => {
    const dateMonthBefore = moment().subtract(1, 'months');
    return dateMonthBefore.isAfter(date);
  }

  const handleFinishCampaignNow = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    const now = moment()
    handleSave(now)
  }

  const handleClickModal = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  const renderCalendarTitle = () => {
    return <DayPickerTitle>Select Campaign Date Range</DayPickerTitle>
  }

  const onFocusChange = (newFocusedDateInput: TFocusedDateType | null) => {
    setFocusedDateType(newFocusedDateInput)
  }

  useEffect(() => {
    setStartDate(moment(existingStartDate))
    setEndDate(moment(existingEndDate))
  }, [])

  return (
    <>
      { shouldShow && (
        <Container>
          {hasStarted ? (
            <>
              <LeftButtonContainer>
                <SmallButton onClick={handleFinishCampaignNow}>Finish campaign now</SmallButton>
              </LeftButtonContainer>
              <LeftButtonContainer>
                <SmallButton onClick={openEditExclusionDatesModal}>Change dates</SmallButton>
              </LeftButtonContainer>
              {/* <SmallButton onClick={openEditDatesModal}>Change dates</SmallButton> */}
            </>
          ) : (
            <>
            <LeftButtonContainer>
              <SmallButton onClick={openEditExclusionDatesModal}>Change dates</SmallButton>
            </LeftButtonContainer>
            </>
          )}
          <Modal isOpen={isEditExclusionDatesModalOpen} handleClose={closeEditExclusionEndDateModal}>
            <EditDatesContainer onClick={handleClickModal}>
              <ModalTitle>Edit campaign dates</ModalTitle>
                <CampaignDateRangePickerContainer aria-hidden="false">
                <Wrapper>
                  <DateRangePicker
                    startDate={startDate}
                    startDateId="campaign_start_date_id"
                    endDate={endDate}
                    endDateId="campaign_end_date_id"
                    onDatesChange={onDatesChange}
                    focusedInput={focusedDateType}
                    onFocusChange={onFocusChange}
                    customArrowIcon={<CustomArrowIcon>to</CustomArrowIcon>}
                    renderCalendarInfo={renderCalendarTitle}
                    displayFormat="DD-MMM-YY"
                    isOutsideRange={handleOutsiteRange}
                  />
                </Wrapper>
                  <ExclusionDateFilter
                    setExclusionDatesApplied={setExclusionDatesApplied}
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    existingExclusionDates={formattedExistingExclusionDates}
                  />
                  <SaveButton  onClick={handleSave}>Save</SaveButton>
              </CampaignDateRangePickerContainer>
            </EditDatesContainer>
          </Modal>
        </Container>
      )}

      <Snackbar open={shouldShowEndNowSuccessMessage} autoHideDuration={SUCCESS_MESSAGE_TIMEOUT} onClose={closeSuccessMessage}>
        <MuiAlert onClose={closeSuccessMessage} severity="success">
          This campaign will end at midnight.
        </MuiAlert>
      </Snackbar>
    </>
  )
}

const mapDispatchToProps = {
  handleUpdateDates: campaignUpdateDatesRequest,
};

export default connect(null, mapDispatchToProps)(EditDatesButtons)

const Wrapper = styled.div`
  align-self: flex-start;
  z-index: 3;
  margin-bottom: 20px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const LeftButtonContainer = styled.div`
  margin-right: 16px;
`

const SmallButton = styled(Button)`
  && {
    background-color: ${theme.colorDarkBlue};
    padding: 0.5625rem 1.2rem 0.5rem 1.2rem;
    font-family: ${theme.ffSansProRegular};
    font-weight: normal;
    font-stretch: normal;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    width: auto;
    height: auto;
  }
`;

const EditDatesContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 4%);
  padding: 1.25rem 1.688rem 1.313rem 1.063rem;
  border-radius: 4px;
  // width: 540px;
  height: 100%;
`

const ModalTitle = styled.div`
  font-family: ${theme.ffInterRegular};
  font-size: 1.125rem;
  line-height: 1.39;
  color: ${theme.titleColor};
`;

const CampaignDateRangePickerContainer = styled.div`
  margin-top: 1.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SaveButton = styled(SmallButton)`
  margin-top: 1.25rem;
`

const CustomArrowIcon = styled.span`
  font-family: ${theme.ffSansProRegular};
  font-size: 0.8125rem;
  font-weight: normal;
  color: ${theme.colorDarkGray};
  padding: 8px 11px 6px;
`;

const DayPickerTitle = styled.div`
  font-family: ${theme.ffSansProRegular};
  font-size: 1.125rem;
  color: ${theme.colorDarkGray};
  padding: 20px;
`;